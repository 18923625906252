import React, { useEffect, useContext, useState, useRef } from 'react';
import gsap from 'gsap';
import {ReactComponent as SkillsSvg} from '../addons/img/skills-img.svg';
import Button from '../components/Button';
import Loader from '../components/Loader';
import firebase from "../functions/firebase";
import { LangugeContext } from "../context/LanguageContext";
import {downloadPageData, getSavedData }  from "../functions/GetData";




const Skills= () => {
        //Variables needed to: download data, set page content
        const [ en ] = useContext(LangugeContext);              // Chosen Language
        const [ loading, setLoading ] = useState(true);         // If true loader component should appear
        const [ apiError, setApiError ] = useState(null);       // Catch error message
        const [ pageContent, setPageContent ] = useState(null); // Page Content

        // IMPORTANT: Need to be changed on every page
        const enStorageKey = "en_Skills"; // English version of the page content sessionStorage
        const plStorageKey = "pl_Skills"; // Polish version of the page content sessionStorage
        const docName = "skills";         // Document to download from API

        // Same for every page
        let storageKey = useRef(en ? enStorageKey : plStorageKey);                  // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)




    //DOWNLOAD or USE SAVED DATA
    useEffect(() => {

        storageKey.current = en ? enStorageKey : plStorageKey;                      // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
        const storage = sessionStorage.getItem(storageKey.current);                 // SessionStorage based on chosen language

            // Download & Save Data - Only if it's not already done
            if(storage === null) {
                downloadPageData(      //*   Function to download and save data from API
                    storageKey.current,  // Key to storage - depends from currently chosen language
                    ref,                 // Path to collect page data from API
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                    setPageContent,      // Set downloaded data as page content
                    setLoading           // true = show loader, false = hide loader and show page content
                 )
            } 

            // Use SAVED data
            else if ( storage !== null) {
                getSavedData (         //*   Function to get saved data from store
                    storageKey.current,  // Key to storage - depends from currently chosen language ex. 'en_Contact'
                    setLoading,          // true = show loader, false = hide loader and show page content
                    setPageContent,      // Set downloaded data as page content
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                )
            } 

     }, [ en ]);




   
    
    // ANIMATIONS
    // Put animations in here:
    const animatePage = useRef(
        () => {
            // About Text - Get elements to animate 
            const skills = document.getElementsByClassName('skills');
            const skillContainer = document.getElementsByClassName('__skills-info');
            const skillSvg = document.getElementsByClassName('__skills-img');
            const skillTags = document.getElementsByClassName('__skill-tags');
            const skillButton = document.getElementsByClassName('btn-default');


            //About Text - Setup
            const tlSkillsInfo = gsap.timeline({defaults: {ease: 'power3.easeOut',autoAlpha: 1, x: 0, y:0}});
            gsap.set([skills,skillContainer,skillSvg], { autoAlpha: 0, x: 0, y:0 } );

            //About Text - Animation
            tlSkillsInfo
            .fromTo(skills, { autoAlpha: 0 }, { duration: 1 })
            .fromTo(skillSvg, { y: '-=300', autoAlpha: 0 }, { duration: 1.5 } )
            .fromTo(skillContainer, { x: '-=500', autoAlpha: 0 }, { duration: 1.5 }, '-=1.3')
            .from(skillTags, { x: '-=500', autoAlpha: 0, duration: 1.5 }, '-=.8')
            .from(skillButton, { x: '-=500', autoAlpha: 0,duration: 1.5 }, '-=.8')

            }
    );

    // Animations handler - try to animate on loading changes
    useEffect(()=>  {

        // IMPORTANT: if loading===true loading page will not animate which will prevent from causing gsap errors (no elements to animate in loader component)
        if( loading === true ) {
            return;
        } 
        // Play animation
        animatePage.current() 
    }, [ loading ]);











    // NO PAGE DATA or CHANGE PAGE DATA
    if ( loading ){
        return (
                <Loader 
                     downloadPageData={()=> 
                        downloadPageData(      //*   Function to download and save data from API
                            storageKey.current,  // Key to storage - depends from currently chosen language
                            ref,                 // Path to collect page data from API
                            en,                  // Currently chosen language
                            setApiError,         // Set error text content
                            setPageContent,      // Set downloaded data as page content
                            setLoading           // true = show loader, false = hide loader and show page content
                         )} 
                    apiError={apiError}
                    en={en}
                />
        );
    } 
    // PAGE DATA READY
    else {
        const { button, title, tags, paragraphs} = pageContent;

        // const paragraphsArray = paragraphs;
        const arrayOfParagraphs = paragraphs.map(paragraph => <p key={paragraph.id} className="main-p">{paragraph.content}</p> );

        const tagsArray = tags;
        const tagsItemsCount = tagsArray.length - 1;
        const arrayOfElements = tagsArray.map((tag, i) => i < tagsItemsCount ? <span className="__tag" key={tag}>{tag + ', '}</span> : <span className="__tag" key={tag}>{tag}</span> );
        return ( 
            <main className="page-container skills noselect">

                <section className='__skills-info'>
                    <h1 className="main-h">{title}</h1>
                        { arrayOfParagraphs }
                        <section className="__skill-tags">
                            {arrayOfElements}   
                        </section>

                        <Button 
                            title={button}
                            id="btn-about"
                            path="/MyWork"
                            class="btn-default"
                        />
                </section>

                <section className="svg-container">
                    <div className="__skills-img">
                        <SkillsSvg className="skills-svg" alt='Skills Image'/>
                    </div>
                </section>

            </main>
        ); 
    }   
}
 
export default Skills;

