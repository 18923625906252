import React, { useEffect, useContext, useState, useRef } from 'react';
import gsap from 'gsap';
import Button from '../components/Button';
import Loader from '../components/Loader';
import firebase from "../functions/firebase";
import { LangugeContext } from "../context/LanguageContext";
import {downloadPageData, getSavedData }  from "../functions/GetData";

const About= () => {
        //Variables needed to: download data, set page content
        const [ en ] = useContext(LangugeContext);              // Chosen Language
        const [ loading, setLoading ] = useState(true);         // If true loader component should appear
        const [ apiError, setApiError ] = useState(null);       // Catch error message
        const [ pageContent, setPageContent ] = useState(null); // Page Content

        // IMPORTANT: Need to be changed on every page
        const enStorageKey = "en_About"; // English version of the page content sessionStorage
        const plStorageKey = "pl_About"; // Polish version of the page content sessionStorage
        const docName = "about";         // Document to download from API

        // Same for every page
        let storageKey = useRef(en ? enStorageKey : plStorageKey);                  // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)


        

    //DOWNLOAD or USE SAVED DATA
    useEffect(() => {

        storageKey.current = en ? enStorageKey : plStorageKey;                      // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
        const storage = sessionStorage.getItem(storageKey.current);                 // SessionStorage based on chosen language

            // Download & Save Data - Only if it's not already done
            if(storage === null) {
                downloadPageData(      //*   Function to download and save data from API
                    storageKey.current,  // Key to storage - depends from currently chosen language
                    ref,                 // Path to collect page data from API
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                    setPageContent,      // Set downloaded data as page content
                    setLoading           // true = show loader, false = hide loader and show page content
                 )
            } 

            // Use SAVED data
            else if ( storage !== null) {
                getSavedData (         //*   Function to get saved data from store
                    storageKey.current,  // Key to storage - depends from currently chosen language ex. 'en_Contact'
                    setLoading,          // true = show loader, false = hide loader and show page content
                    setPageContent,      // Set downloaded data as page content
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                )
            } 

     }, [ en ]);
    

    


    // ANIMATIONS
    // Put animations in here:
    const animatePage = useRef(
        () => {
            const about = document.getElementsByClassName('about');
            // About Text - Get elements to animate 
            const aboutH1 = document.getElementById('about-me-h');
            const aboutP = document.getElementById('about-p');
            const aboutBtn = document.getElementById('btn-about');
    
    
            //About Text - Setup
            const tlAboutText = gsap.timeline({defaults: {ease: 'power3.easeOut', x:0}});
            gsap.set([about,aboutH1, aboutP, aboutBtn], {
                autoAlpha: 0,
                x: 0
            })
    
             //About Text - Animation
             tlAboutText
             .fromTo(about, {opacity: 0, autoAlpha: 0}, {delay: .1, duration: .1, autoAlpha: 1})
             .fromTo(aboutH1, {x: '-=300'}, {duration: .5, autoAlpha: 1,delay: .5})
             .fromTo(aboutP, {x: '-=300'}, {duration: .8, autoAlpha: 1}, '-=0.2')
             .fromTo(aboutBtn, {x: '-=300'}, {duration: .5, autoAlpha: 1}, '-=0.2');
    
            }
    );

   // Animations handler - try to animate on loading changes
   useEffect(()=>  {

    // IMPORTANT: if loading===true loading page will not animate which will prevent from causing gsap errors (no elements to animate in loader component)
    if( loading === true ) {
        return;
    } 
    // Play animation
    animatePage.current() 
    }, [ loading ]);
    
    
    // NO PAGE DATA or CHANGE PAGE DATA
    if ( loading ){
        return (
                <Loader 
                     downloadPageData={()=> 
                        downloadPageData(      //*   Function to download and save data from API
                            storageKey.current,  // Key to storage - depends from currently chosen language
                            ref,                 // Path to collect page data from API
                            en,                  // Currently chosen language
                            setApiError,         // Set error text content
                            setPageContent,      // Set downloaded data as page content
                            setLoading           // true = show loader, false = hide loader and show page content
                         )} 
                    apiError={apiError}
                    en={en}
                />
        );
    }
    // PAGE DATA READY
    else {
        const { h1, p1, p2, p3, button } = pageContent;
        return ( 
            <main className="page-container about noselect">
                <section className="about-me">
                    <h1 id="about-me-h" className="main-h">{h1}</h1>
                    <section id="about-p">
                        <p className="main-p">
                            {p1}
                        </p>
                        <p className="main-p">
                            {p2}
                        </p>
                        <p className="main-p">
                            {p3}
                        </p>
                    </section>
                    
                    {/* <NavLink to='/Skills'><div id="btn-about" className="btn-default btn-about"> My Skills</div></NavLink> */}
                    <br/>
                    <Button 
                    title={button}
                    id="btn-about"
                    path="/Skills"
                    class="btn-default"
                    />
                </section>    
            </main>
        );
    }
}
 
export default About;

