import React, { useEffect, useState, useContext, useRef } from 'react';
import gsap from 'gsap';
import Map from './Map';
import EmailSender from '../functions/EmailSender';
import Loader from '../components/Loader';
import firebase from "../functions/firebase";
import { LangugeContext } from "../context/LanguageContext";
import {downloadPageData, getSavedData }  from "../functions/GetData";



const Contact= () => {
        //Variables needed to: download data, set page content
        const [ en ] = useContext(LangugeContext);              // Chosen Language
        const [ loading, setLoading ] = useState(true);         // If true loader component should appear
        const [ apiError, setApiError ] = useState(null);       // Catch error message
        const [ pageContent, setPageContent ] = useState(null); // Page Content

        // IMPORTANT: Need to be changed on every page
        const enStorageKey = "en_Contact"; // English version of the page content sessionStorage
        const plStorageKey = "pl_Contact"; // Polish version of the page content sessionStorage
        const docName = "contact";         // Document to download from API

        // Same for every page
        let storageKey = useRef(en ? enStorageKey : plStorageKey);                  // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)

     
     
     
     //DOWNLOAD or USE SAVED DATA
     useEffect(() => {

        storageKey.current = en ? enStorageKey : plStorageKey;                      // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
        const storage = sessionStorage.getItem(storageKey.current);                 // SessionStorage based on chosen language

            // Download & Save Data - Only if it's not already done
            if(storage === null) {
                downloadPageData(      //*   Function to download and save data from API
                    storageKey.current,  // Key to storage - depends from currently chosen language
                    ref,                 // Path to collect page data from API
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                    setPageContent,      // Set downloaded data as page content
                    setLoading           // true = show loader, false = hide loader and show page content
                 )
            } 

            // Use SAVED data
            else if ( storage !== null) {
                getSavedData (         //*   Function to get saved data from store
                    storageKey.current,  // Key to storage - depends from currently chosen language ex. 'en_Contact'
                    setLoading,          // true = show loader, false = hide loader and show page content
                    setPageContent,      // Set downloaded data as page content
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                )
            } 

     }, [ en ]);

 
   

     

    // ANIMATIONS
    // Put animations in here:
    const animatePage = useRef(
        () => {
            // Get elements to animate 
            const contact = document.getElementsByClassName('contact');
            const contactDetails = document.getElementById('contact-details');
            const contactH = document.getElementById('contact-h');
            const field1 = document.getElementById('field1');
            const field2 = document.getElementById('field2');
            const field3 = document.getElementById('field3');
            const field4 = document.getElementById('field4');
            const btnContact = document.getElementById('btn-contact');
            const mapContainer = document.getElementById('map-container');
            
            let pageTl = gsap.timeline({ defaults: { duration: .5, y: 0,ease: 'power3.easeOut' } });
            gsap.set([contact, contactDetails, contactH, field1, field2, field3, field4, btnContact, mapContainer], {
                autoAlpha: 0, 
                y: 0
              })
    
    
            // Animation
            pageTl
            .to(contact, {duration: .1, autoAlpha: 1}, 0.1)
            .fromTo(contactDetails, {y: '-=150'}, { duration: .2, autoAlpha: 1 })
            .fromTo(contactH, {y: '-=150'}, { duration: 1, autoAlpha: 1 })
            .fromTo(field1, {y: '+=400'}, { autoAlpha: 1 }, '-=0.15')
            .fromTo(field2, {y: '+=400'}, { autoAlpha: 1 }, '-=0.15')
            .fromTo(field3, {y: '+=400'}, { autoAlpha: 1 }, '-=0.15')
            .fromTo(field4, {y: '+=400'}, { autoAlpha: 1 }, '-=0.15')
            .fromTo(btnContact, {y: '+=400'}, { autoAlpha: 1 }, '-=0.15')
            .fromTo(mapContainer, {y: '+=100%'}, { duration: .5, autoAlpha: 1 }, '-=0.5')
    
            }
    );

    // Animations handler - try to animate on loading changes
    useEffect(()=>  {

        // IMPORTANT: if loading===true loading page will not animate which will prevent from causing gsap errors (no elements to animate in loader component)
        if( loading === true ) {
            return;
        } 
        // Play animation
        animatePage.current() 
    }, [ loading ]);




    // NO PAGE DATA or CHANGE PAGE DATA
    if ( loading ){
        return (
                <Loader 
                     downloadPageData={()=> 
                        downloadPageData(      //*   Function to download and save data from API
                            storageKey.current,  // Key to storage - depends from currently chosen language
                            ref,                 // Path to collect page data from API
                            en,                  // Currently chosen language
                            setApiError,         // Set error text content
                            setPageContent,      // Set downloaded data as page content
                            setLoading           // true = show loader, false = hide loader and show page content
                         )} 
                    apiError={apiError}
                    en={en}
                />
        );
    } 
    // PAGE DATA READY
    else {

        const { title, nameField, emailField, subjectField, messageField, btnSend } = pageContent;
        return (
            <main className="page-container contact noselect">
                <section className="contact-section">
                    <EmailSender
                        title= { title }
                        nameField = { nameField }
                        emailField = { emailField }
                        subjectField = { subjectField }
                        messageField = { messageField }
                        btnSend= { btnSend }
                    />

                    <section id="map">
                        <Map/>
                    </section>
                </section>
            </main>
        );
    }
}
 
export default Contact;