import React, { useContext } from 'react';
import { LangugeContext } from "../context/LanguageContext";


const NavAside = () => {
    // const [en, setEng] = useState(true);
    const [en, setEng] = useContext(LangugeContext);

    
        let setLanguage = (language) => {
            
            if(language === 'en') {
                setEng(true)
                // console.log('USTAW EN');
            }
            else {
                setEng(false)
                // console.log('USTAW PL');
            }
        }

    
    return(
        <nav id="nav-aside" className="aside-nav noselect">
            <section className="__buttons">
                <div
                onClick={()=> setLanguage('pl')}
                className={en ? '__btn' : '__btn active'}
                id='pl'>PL
                </div>
                

                <div 
                onClick={()=> setLanguage('en')} 
                className={en ? '__btn active' : '__btn'}
                id='en'>EN
                </div>
            </section>
        </nav>
    )
}

export default NavAside;