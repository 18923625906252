import React from 'react';
import {NavLink} from 'react-router-dom';
import { useHistory } from "react-router";
import gsap from 'gsap';

const Button= (props) => {

    const pageLeave = ()=> {
        
        // Get page 
        const page = document.getElementsByClassName('page-container'); 
       
        //Setup
        // gsap.set(page, {x: '-=0', y: '-=0'});
        const tlPageLeave = gsap.timeline({defaults: {ease: 'power3.easeOut'}});

        //Animation
        tlPageLeave
        .fromTo(page,{}, {duration: .6, x: '+=200', autoAlpha: 0}); 
    }

    const history = useHistory();

    const delayLink = (link,e) =>  { 
        let prevLink = history.location.pathname;
        if (prevLink === link) {
            return
        }
        e.preventDefault();
        pageLeave();
        setTimeout(() => { 
             history.push(`${link}`) 
            },400);
    }


    return ( 
        <NavLink onClick={(e)=> delayLink(`${props.path}`,e)} to={`${props.path}`}><div id={props.id} className={`${props.class}`}> {props.title}</div></NavLink>
    )
}


export default Button;