import React, { useEffect, useState, useContext, useRef } from 'react';
import gsap from 'gsap';
import Project from '../components/Project';
import Carousel from 'react-elastic-carousel';
import Loader from '../components/Loader';
import firebase from "../functions/firebase";
import { FaRegEye } from "react-icons/fa";
import { LangugeContext } from "../context/LanguageContext";
import {downloadPageData, getSavedData }  from "../functions/GetData";





const MyWork= () => {
         //Variables needed to: download data, set page content
         const [ en ] = useContext(LangugeContext);              // Chosen Language
         const [ loading, setLoading ] = useState(true);         // If true loader component should appear
         const [ apiError, setApiError ] = useState(null);       // Catch error message
         const [ pageContent, setPageContent ] = useState(null); // Page Content
 
         // IMPORTANT: Need to be changed on every page
         const enStorageKey = "en_MyWork"; // English version of the page content sessionStorage
         const plStorageKey = "pl_MyWork"; // Polish version of the page content sessionStorage
         const docName = "mywork";         // Document to download from API
 
         // Same for every page
         let storageKey = useRef(en ? enStorageKey : plStorageKey);                  // SessionStorage Key based on chosen language
         const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
 



    //DOWNLOAD or USE SAVED DATA
    useEffect(() => {

    storageKey.current = en ? enStorageKey : plStorageKey;                      // SessionStorage Key based on chosen language
    const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
    const storage = sessionStorage.getItem(storageKey.current);                 // SessionStorage based on chosen language

        // Download & Save Data - Only if it's not already done
        if(storage === null) {
            downloadPageData(      //*   Function to download and save data from API
                storageKey.current,  // Key to storage - depends from currently chosen language
                ref,                 // Path to collect page data from API
                en,                  // Currently chosen language
                setApiError,         // Set error text content
                setPageContent,      // Set downloaded data as page content
                setLoading           // true = show loader, false = hide loader and show page content
                )
        } 

        // Use SAVED data
        else if ( storage !== null) {
            getSavedData (         //*   Function to get saved data from store
                storageKey.current,  // Key to storage - depends from currently chosen language ex. 'en_Contact'
                setLoading,          // true = show loader, false = hide loader and show page content
                setPageContent,      // Set downloaded data as page content
                en,                  // Currently chosen language
                setApiError,         // Set error text content
            )
        } 

    }, [ en ]);

 
   

     

    // ANIMATIONS
    // Put animations in here:
    const animatePage = useRef(
        () => {
            // MyWork - Get elements to animate 
            const mywork = document.getElementsByClassName('mywork');
            const work = document.getElementById('work');
            const smallProjects = document.getElementById('small-projects');
            const projectsCards = document.querySelectorAll('.project');
            const notify = document.getElementsByClassName('notify');


            //MyWork - Setup
            gsap.set([mywork,work, smallProjects, notify], {autoAlpha: 0});
            gsap.set([projectsCards], {scale: 1});
            const tlMyWork = gsap.timeline({defaults: {ease: 'power3.easeOut'}});

            //MyWork - Animation
            tlMyWork
            .fromTo(mywork, {opacity: 0, autoAlpha: 0}, {delay: .1, duration: .1, opacity: 1, autoAlpha: 1})
            .fromTo(work, {x: '-=100%'}, {duration: 1, opacity: 1, autoAlpha: 1,x: '+=100%'})
            .fromTo(smallProjects, {x: '+=100%'}, {duration: 1, x: '-=100%', autoAlpha: 1}, '-=1')
            .fromTo(notify,{x: '+=100%'}, {duration: 2, autoAlpha: 1, x: 0})
            .to(notify, {duration: 1, autoAlpha: 0, delay: 5, x: '+=100%'})
            // .fromTo(projectsCards, {scale: 1.05}, {duration: .1, scale: 1})

            }
    );

    // Animations handler - try to animate on loading changes
    useEffect(()=>  {

        // IMPORTANT: if loading===true loading page will not animate which will prevent from causing gsap errors (no elements to animate in loader component)
        if( loading === true ) {
            return;
        } 
        // Play animation
        animatePage.current() 
    }, [ loading ]);



    // IMPORTANT (for slider):
    // This breakpoints are for div.rec-slider-container size NOT screen size!
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 320, itemsToShow: 1, pagination: false },
        { width: 550, itemsToShow: 1, pagination: false },
        { width: 1000, itemsToShow: 2, itemsToScroll: 1 },
        { width: 1150, itemsToShow: 3, itemsToScroll: 1 },
    ];



    
    




    // NO PAGE DATA or CHANGE PAGE DATA
    if ( loading ){
    return (
            <Loader 
                    downloadPageData={()=> 
                    downloadPageData(      //*   Function to download and save data from API
                        storageKey.current,  // Key to storage - depends from currently chosen language
                        ref,                 // Path to collect page data from API
                        en,                  // Currently chosen language
                        setApiError,         // Set error text content
                        setPageContent,      // Set downloaded data as page content
                        setLoading           // true = show loader, false = hide loader and show page content
                        )} 
                apiError={apiError}
                en={en}
            />
    );
    } 
    // PAGE DATA READY
    else {
        const { label1, label2, projectCards, workCards, technologiesTitle } = pageContent;

        const workContent = workCards.map((card, i) => 
                <Project
                    key={card.id}
                    name={card.title}
                    technologiesTitle={technologiesTitle}
                    technologies={card.technologies}
                    excerpt={card.excerpt}
                    content={card.content}
                    link={card.link}
                /> 
         );
        const projects = projectCards.map((card, i) => 
            <Project
                key={card.id}
                name={card.title}
                technologiesTitle={technologiesTitle}
                technologies={card.technologies}
                excerpt={card.excerpt}
                content={card.content}
                link={card.link}
            /> 
        );
   
        return ( 
            <main id="page-container" className="page-container mywork noselect">

                        <section id="work" className="work">
                            <div className="project-category">
                                <h2 className="__title">{label1}</h2>
                            </div>
                                
                                <Carousel showEmptySlots={true} pagination={false} breakPoints={breakPoints}>
                                    { workContent }
                                </Carousel>
                        </section>


                        <section id="small-projects" className="small-projects">
                            <div className="project-category">
                                <h2 className="__title">{label2}</h2>
                            </div>
                            
                                <Carousel showEmptySlots={true} pagination={false} breakPoints={breakPoints}>
                                    { projects }
                                </Carousel>
                        </section>
                        <aside className="notify">{en ? <p>An icon for more information</p> : <p>Ikona oznaczająca więcej informacji</p> }  <div className="__icon-container"><FaRegEye /></div></aside>
            </main>
        );
    }
}
 
export default MyWork;

