import React, { Component } from 'react';
import '../scss/App.scss';
import Home from '../pages/Home';
import About from '../pages/About';
import Skills from '../pages/Skills';
import MyWork from '../pages/MyWork';
import Contact from '../pages/Contact';
import Nav from '../pages/Nav';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {LanguageProvider} from "../context/LanguageContext";



class App extends Component {
  state = {}

  render() {
    return (
      <>
      <LanguageProvider>
        <Router>
        <Nav />
        
          
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route path='/about' component={About} />
                  <Route path='/skills' component={Skills} />
                  <Route path='/mywork' component={MyWork} />
                  <Route path='/contact' component={Contact} />
                  {/* <Route path='/contact' render={props => <Contact title="Contact Me" btnSend="Send" {...props}/>} /> */}
                  <Route component={Home} />
                </Switch>

          
                
        </Router>
      </LanguageProvider>
      </>
        

    );
  }
}


export default App;