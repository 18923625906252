import React, { useState, createContext } from 'react';

export const LangugeContext = createContext();

export const LanguageProvider = props => {
    const [en, setEng] = useState(true);


    return (
        <LangugeContext.Provider value={[en, setEng]}>
            {props.children}
        </LangugeContext.Provider>
    );


} 