import React, { useState, useEffect, useRef, useContext } from 'react';
import '../scss/Loader.scss';
import { FiRefreshCw } from "react-icons/fi";
import { LangugeContext } from "../context/LanguageContext";




const ErrorHandler = (props) => {
    const { apiError, downloadPageData, refreshVisible, setVisibleRefresh } = props;
    const [ en ] = useContext(LangugeContext);              // Chosen Language
    
    return (
        
        <>
        
            {
                refreshVisible 
            ?
                <>
                    <div className="loader-info">
                    <p className="loader-p">{apiError ? apiError : en ? "Something went wrong..." : "Coś poszło nie tak..."}</p>
                    </div>

                    <FiRefreshCw 
                    className="btn-loader"
                    onClick={() => {
                        downloadPageData();
                        setVisibleRefresh(false);
                        // clearTimeout(refresh);
                    }}
                    />
                </>
            
            : 
            <div className="loader-info">
            <p className="loader-p">{ en ? "I'm working on it!" : "Pracuję nad tym!" }</p>
            </div>
            }
        </>
        
    )
}




const Loader = (props) => {
    // Variables
    const [ refreshVisible, setVisibleRefresh ] = useState(false);
    const delayRefreshButton = 3500; // Time in ms
    const { apiError, downloadPageData, en } = props;
    let refresh  = useRef();




    // REFRESH button & message - delay
    useEffect(() => {

            refresh.current = setTimeout(() => {
                setVisibleRefresh(true);
            }
            , delayRefreshButton);

            return ()=> clearTimeout(refresh.current);
       
    }, [ apiError, refreshVisible ]);
    

       
    

    useEffect(() => {
        // Hide button and clear timeout on language change
        return () => {
            clearTimeout(refresh.current);
            setVisibleRefresh(false);
        }
    }, [en])


    return ( 

        
        <div className="page-container loader">
            <div className="loader-container">
                <div className="loader-wrapper">
                    <div className="loader-image">
                        <div className="cssload-loader">
                            <div className="cssload-inner cssload-one"></div>
                            <div className="cssload-inner cssload-two"></div>
                            <div className="cssload-inner cssload-three"></div>
                        </div>
                    </div>
                    
                   
                    <ErrorHandler 
                        apiError={apiError}
                        downloadPageData={downloadPageData}
                        refreshVisible={refreshVisible}
                        setVisibleRefresh={()=>setVisibleRefresh()}
                    />
                
                </div>
            </div>
        </div>
     );
}
 
export default Loader;