import React, {useRef, useEffect, useState, useContext} from 'react';
import {ReactComponent as Scene} from '../addons/img/header-img.svg';
import gsap from 'gsap';
import Button from '../components/Button';
import Loader from '../components/Loader';
import firebase from "../functions/firebase";
import { LangugeContext } from "../context/LanguageContext";
import {downloadPageData, getSavedData }  from "../functions/GetData";
// import { AiFillNotification } from "react-icons/ai";



const Home = () => {
    //Variables needed to: download data, set page content
    const [ en ] = useContext(LangugeContext);              // Chosen Language
    const [ loading, setLoading ] = useState(true);         // If true loader component should appear
    const [ apiError, setApiError ] = useState(null);       // Catch error message
    const [ pageContent, setPageContent ] = useState(null); // Page Content
    const svgContainer = useRef(null);

    // IMPORTANT: Need to be changed on every page
    const enStorageKey = "en_Home"; // English version of the page content sessionStorage
    const plStorageKey = "pl_Home"; // Polish version of the page content sessionStorage
    const docName = "home";         // Document to downloHd from API

    // Same for every page
    let storageKey = useRef(en ? enStorageKey : plStorageKey);                  // SessionStorage Key based on chosen language
    const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
    
    


     //DOWNLOAD or USE SAVED DATA
     useEffect(() => {

        storageKey.current = en ? enStorageKey : plStorageKey;                      // SessionStorage Key based on chosen language
        const ref = firebase.firestore().collection(en ? "EN" : "PL").doc(docName); // Path to API doc (change doc value)
        const storage = sessionStorage.getItem(storageKey.current);                 // SessionStorage based on chosen language

            // Download & Save Data - Only if it's not already done
            if(storage === null) {
                downloadPageData(      //*   Function to download and save data from API
                    storageKey.current,  // Key to storage - depends from currently chosen language
                    ref,                 // Path to collect page data from API
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                    setPageContent,      // Set downloaded data as page content
                    setLoading           // true = show loader, false = hide loader and show page content
                 )
            } 

            // Use SAVED data
            else if ( storage !== null) {
                getSavedData (         //*   Function to get saved data from store
                    storageKey.current,  // Key to storage - depends from currently chosen language ex. 'en_Contact'
                    setLoading,          // true = show loader, false = hide loader and show page content
                    setPageContent,      // Set downloaded data as page content
                    en,                  // Currently chosen language
                    setApiError,         // Set error text content
                )
            } 

     }, [ en ]);


  


     // ANIMATIONS
    // Put animations in here:
    const animatePage = useRef(
        () => {
             // Home Text - Get elements to animate 
             const home = document.getElementsByClassName('home');
             const headerH2 = document.getElementById('header-h2');
             const headerH4 = document.getElementById('header-h4');
             const headerBtn = document.getElementById('btn-home');
 
 
             //Home Text - Setup
             gsap.set([home,headerH2, headerH4, headerBtn], {autoAlpha: 0});
             const tlHomeText = gsap.timeline({defaults: {ease: 'power3.easeOut'}});
 
             //Home Text - Animation
             tlHomeText
             .fromTo(home,{autoAlpha:0}, {delay:.1,duration: .1, autoAlpha:1})
             .fromTo(headerH2, {y: '-=300'}, {ease:'Back.easeOut',duration: 1, autoAlpha: 1,y: '+=300',delay: .3})
             .fromTo(headerH4, {x: '-=300'}, {x: '+=300', autoAlpha: 1}, '-=0.2')
             .fromTo(headerBtn, {x: '-=300'}, {x: '+=300', autoAlpha: 1}, '-=0.2');
 
 
 
 
             // Hero Img - Get elements to animate 
             const [svgEl]= svgContainer.current.children;
 
             const monitorRight = svgEl.getElementById('monitor-right');
             const monitorMiddle = svgEl.getElementById('monitor-middle');
             const monitorLeft = svgEl.getElementById('monitor-left');
             const flower = svgEl.getElementById('flower');
             const personAndChair = svgEl.getElementById('person-and-chair');
             const monitorMain = svgEl.getElementById('monitor-main');
             const monitorMainContent = svgEl.getElementById('monitor-main-content');
             const monitorLTcontent = svgEl.getElementById('monitor-left-top-content');
             const monitorLT = svgEl.getElementById('monitor-left-top');
             const monitorRT = svgEl.getElementById('monitor-right-top');
             const table = svgEl.getElementById('table');
             const carpet = svgEl.getElementById('carpet');
             const bg = svgEl.getElementById('bg');
            //  const notify = document.getElementsByClassName('notify');

 
             
             // Hero Img - Setup
             gsap.set([home,monitorRight,monitorMiddle,monitorLeft,flower,personAndChair,monitorMain,...monitorMainContent.children,monitorLTcontent,monitorLT,monitorRT,table,carpet,bg], {autoAlpha: 0});
 
             gsap.set([monitorLeft,monitorRight,monitorRT,monitorLT,monitorLTcontent,monitorMiddle], {scale: 0.2})
 
             const tlHeroImg = gsap.timeline({defaults: {ease: 'power3.easeOut'}});
 
         
             //Hero Img - Animation
             tlHeroImg
             .fromTo(home, {}, {duration: .5,autoAlpha: 1,delay: 1})
             .fromTo(bg, {scale: 0.9}, {duration: 1, autoAlpha: .24, scale: 1}, '-=.5')
             .fromTo([table,flower,carpet,monitorMain], {x: '+=400'}, {duration: .3, autoAlpha: 1, x:'-=400'}, '-=0.5')
             .fromTo(personAndChair, {x: "+=450"} , {duration: .5,autoAlpha: 1, x: "-=450"})
             .fromTo(monitorLeft,{x: '+=300'}, {duration: .45, autoAlpha: 1, scale: 1,x: '-=300'}, '-=0.1')
             .fromTo(monitorRight,{x: '-=200'}, {duration: .45, autoAlpha: 1,scale: 1, x: '+=200'}, '-=0.2')
             .fromTo(monitorRT,{x: '-=300', y:'+=200'}, {duration: .45, autoAlpha: 1,scale: 1, x: '+=300', y:'-=200'}, '-=0.2')
             .fromTo([monitorLT,monitorLTcontent],{x: '+=300',y:'+=200'}, {duration: .45, autoAlpha: 1,scale: 1, x: '-=300',y:'-=200'}, '-=0.2')
             .fromTo(monitorMiddle,{x: '+=200', y:'+=200'}, {duration: .45, autoAlpha: 1, scale: 1, x: '-=200', y:'-=200'})
             .fromTo(monitorMainContent.children,{}, {duration: .7, autoAlpha: 1,stagger: 0.1,ease:'RoughEase.easeOut'})
            //  .fromTo(notify,{x: '+=100%'}, {duration: 2, autoAlpha: 1, x: 0}, "-=5")
            //  .to(notify, {duration: 1, autoAlpha: 0, delay: 5, x: '+=100%'})
    
            }
    );



  
   




    // Animations handler - try to animate on loading changes
    useEffect(()=>  {

        // IMPORTANT: if loading===true loading page will not animate which will prevent from causing gsap errors (no elements to animate in loader component)
        if( loading === true ) {
            return;
        } 
        // Play animation
        animatePage.current() 
    }, [ loading ]);


    // NO PAGE DATA or CHANGE PAGE DATA
    if ( loading ){
        return (
                <Loader 
                     downloadPageData={()=> 
                        downloadPageData(      //*   Function to download and save data from API
                            storageKey.current,  // Key to storage - depends from currently chosen language
                            ref,                 // Path to collect page data from API
                            en,                  // Currently chosen language
                            setApiError,         // Set error text content
                            setPageContent,      // Set downloaded data as page content
                            setLoading           // true = show loader, false = hide loader and show page content
                         )} 
                    apiError={apiError}
                    en={en}
                />
        );
    } 
    // PAGE DATA READY
    else {
        const { name, introduce, button, welcome } = pageContent;
        return ( 
            <header className="page-container home noselect">
                <div className="header-text">
                    <h2 id="header-h2">{welcome}<br/> {introduce} <span id="header-h2-span" data-content="Kamil">{name}</span><br/> Web developer</h2>
                    <h4  id="header-h4">Frontend developer</h4>
                    <Button 
                        title={button}
                        id="btn-home"
                        path="/About"
                        class="btn-default btn-home"
                    />
                </div>

                <div ref={svgContainer} >
                <Scene className="header-image-container" alt='Coding Man'/>
                </div>
            </header>
        )
    }
    
}

export default Home;