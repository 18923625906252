import emailjs from 'emailjs-com';
import React, { Component } from 'react';


export default class EmailSender extends Component {
    
    state = {
        emailNotify: '',
        formClass: '',
        isSending: false
    }

    sendEmail(e) {
        e.preventDefault();
        if(this.state.isSending === true) {
            return;
        }
        this.setState({
            isSending: true
        });

        emailjs.sendForm('service_6vfr1ku', 'template_w4xrhxa', e.target, 'user_6OBzRrCeZgz8ljsq4qFvI')
          .then((result) => {
              console.log(`${result.text} Email Sent!`);

              this.setState({
                  emailNotify: 'OK! Email sent.',
                  formClass: 'form__sent',
                  isSending: false

              });

              const form = document.querySelector('#contact-details');
              form.reset();

          }, (error) => {
              console.log(error.text);

              this.setState({
                emailNotify: 'Sorry! Something went wrong.',
                formClass: 'form__error',
                isSending: false
              });
          });
    
}

render() {
        const { title, nameField, emailField, subjectField, messageField, btnSend} = this.props;

        return (
              
            <form id="contact-details" className="contact-form" onSubmit={this.sendEmail.bind(this)}>
                <h1 id="contact-h" className="main-h">{ title }</h1>
                
                <div id="field1" className="form__group field">
                    <input type="text" className="form__field" placeholder="Name" name="name" id='name'/>
                    <label htmlFor="name" className="form__label">{ nameField }</label>
                </div>

                    <div id="field2" className="form__group field">
                        <input type="email" className="form__field" placeholder="E-Mail" name="e-mail" id='e-mail' required />
                        <label htmlFor="e-mail" className="form__label">{ emailField }*</label>
                    </div>

                <div id="field3" className="form__group field">
                    <input type="input" className="form__field" placeholder="Subject" name="subject" id='subject' required />
                    <label htmlFor="subject" className="form__label">{ subjectField }*</label>
                </div>

                    <div id="field4" className="form__group field">
                        <textarea type="input" className="form__field form__message" placeholder="Message" name="message" id='message' minLength="45" required />
                        <label htmlFor="message" className="form__label">{ messageField }*</label>
                    </div>
                 
                <button className="btn-default form__btn-contact" type="submit" id="btn-contact" >{ btnSend }</button>
                { this.state.emailNotify !== '' ? <p className={this.state.formClass}>{this.state.emailNotify}</p> : null } 
                
            </form>

        )
    }
}