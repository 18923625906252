import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosCloseCircleOutline } from "react-icons/io";




const Hamburger = ({ isOpen,isInitial,setHamburger,setInitial }) => {
//STATE
    const [disabled, setDisabled] = useState(false);


//BUTTONS
    const menuBtnON = 
    <button 
        disabled={disabled}
        onClick={()=> {
            setHamburger(!isOpen);
            disableMenu()
            }}>
        <GiHamburgerMenu  className={disabled ? "disabled" : "active"}/>
    </button>;


    const menuBtnOff = 
    <button disabled={disabled}
     onClick={()=> handleBtnOff()}>
    <IoIosCloseCircleOutline className={disabled ? "hamburger-on disabled" : "hamburger-on active"}/>
    </button>;

//PREVENT from clicking the menu button which can cause a animation errors
    const disableMenu = () => {
        setDisabled(!disabled);

        if(isInitial) {
            console.log('PIERWSZY');
                setTimeout(()=> {
                    setDisabled(false);
            }, 1500)
        }
        else {
            console.log('NIE PIERWSZY');
                setTimeout(()=> {
                    setDisabled(false);
            }, 450)
        }
    }

//SET isInitial to false - menu will open as second time (faster)
    const handleBtnOff = () => {
        if(isInitial){setInitial(false)}
        setHamburger(!isOpen);
        disableMenu();
    }


    return (
        <div id="hamburger-container" className={isOpen ? `__ON  ${disabled ? "disabled" : "active"}` : `__OFF  ${disabled ? "disabled" : "active"}`}>
            {isOpen ? menuBtnOff : menuBtnON }
        </div>
    )
}





export default Hamburger;