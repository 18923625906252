const downloading_loaderShowTime = 700;
const savedData_loaderShowTime = 500;


// DOWNLOAD & SAVE DOWNLOADED DATA
export function downloadPageData( storageKey, ref, en, setApiError, setPageContent, setLoading) {
    // console.info('*************************************');
    //             console.info('DOWNLOAD');
    // console.info('*************************************');
    setLoading(true); // When true - show loader component, it turn to false if data is successfully downloaded
    setApiError(null); // Resets previous error messages - show default loader message



    //ANGIELSKI
    if (en === true ) {
                   
            // DOWNLOAD
            ref.get().then(doc => {
            const items = doc.data();
            
            // If data from API exists
            if (items !== undefined) {

                // SAVE data to sessionStorage 
                let stringObj = JSON.stringify(items);
                sessionStorage.setItem(storageKey, stringObj);
                // SET page content
                setPageContent(items);

                // Turn OFF loading component
                setTimeout(()=> {
                setLoading(false);
                }
                , downloading_loaderShowTime)
            }
            
        })
        .catch(err => {
            console.log('BŁĄD POBIERANIA DANYCH (EN)');
            setApiError(err.message);
        })
    }   // Lang EN - end



    //POLSKI
    else if( en === false ) {
                   
            // DOWNLOAD
            ref.get().then(doc => {
                const items = doc.data();
                
                // If data from API exists
                if (items !== undefined) {
    
                    // SAVE data to sessionStorage 
                    let stringObj = JSON.stringify(items);
                    sessionStorage.setItem(storageKey, stringObj);
                    // SET page content
                    setPageContent(items);
    
                    // Turn OFF loading component
                    setTimeout(()=>setLoading(false), downloading_loaderShowTime)

                }
        
    })
    .catch(err => {
        console.log('BŁĄD POBIERANIA DANYCH (PL)');
        setApiError(err.message);
    })
        
       
    } // Lang PL - end
           
    
};






// USE SAVED DATA FUNCTION
export function getSavedData( storageKey, setLoading, setPageContent, en, setApiError) {
    // console.info('*************************************');
    //             console.info('READ');
    // console.info('*************************************');

                    
    setLoading(true); // When true - show loader component, it turn to false if data is successfully downloaded
    setApiError(null); // Resets previous error messages - show default loader message


                    if (en === true ) {
                        
                        //PARSE
                        const items = JSON.parse(sessionStorage.getItem(storageKey));
                        //SET CONTENT
                        setPageContent(items);
                        //OFF LOADING
                        setTimeout(()=>setLoading(false), savedData_loaderShowTime)

                        // //TEMP
                        // console.log(storageKey);
                        // console.log(items);
                    }
                    else if( en === false ) {
                        //PARSE
                        const items = JSON.parse(sessionStorage.getItem(storageKey));
                        //SET CONTENT
                        setPageContent(items);
                        //OFF LOADING
                        setTimeout(()=>setLoading(false), savedData_loaderShowTime)

                        // //TEMP
                        // console.log(storageKey);
                        // console.log(items);
                    }
                   
                  
    
}