import React, { useState } from 'react'
import { FaRegEye } from "react-icons/fa";
import Modal from 'react-modal';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { ExternalLink } from 'react-external-link';
Modal.setAppElement(document.getElementById('root'));


const Project = (props) => {
    const { name, content, link, technologies, excerpt, technologiesTitle } = props;

        
        const arrayOfElements = technologies.map((tech, i)=> <li key={i}>- {tech}</li>);

      
    


    // MODAL
    const [ showModal, setModal ] = useState(false);       // Show/Hide modal
    // const [ animation, setAnimation ] = useState(null);       // Show/Hide modal
    const handleOpenModal = () => {setModal(true);      // Open modal function
    }
    const handleCloseModal = () => {setModal(false);    // Hide modal function
    }

  



    
    

    return ( 
        <div className="project">

            <div data-content={name} className="__name">
                <h3>{name}</h3>
                <div className="__link" onClick={handleOpenModal}><FaRegEye data-content="Check IT"/></div>
            </div>

                <div className="__about">
                    <div className="technologies">
                        <h3>{ technologiesTitle }</h3>
                        <ul>{ arrayOfElements }</ul>
                    </div>
                    
                    <p className="excerpt">{ excerpt }</p>
                </div>

            <Modal 
                isOpen={showModal}
                contentLabel="Project details"
                onRequestClose={handleCloseModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="modal-wrapper">
                    <div className="__name">
                         <h3>{ name }</h3> 
                         <div className="__link" onClick={handleCloseModal}><IoIosCloseCircleOutline data-content="Check IT"/></div>
                    </div>
                    
                    <div className="__about"> 
                        <p>{ content }</p> 
                        {link 
                        ? 
                            <ExternalLink name={`${name} demo`} alt={`${name} demo`} className="go-to-link-wrapper" href={link}>
                              <p className="__go-to-link">Check It</p>
                            </ExternalLink>
                        : 
                            <div className="no-link-wrapper"><p className="__no-link">No Link</p></div>}
                      
                    </div>
                </div>
            

            </Modal>

        </div>
     );
}
 
export default Project;