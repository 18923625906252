import React, { useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import { AiOutlineHome, AiFillGithub } from "react-icons/ai";
import { SiAboutDotMe } from "react-icons/si";
import { FaRegEye } from "react-icons/fa";
import { VscSettingsGear } from "react-icons/vsc";
import { BiEnvelopeOpen } from "react-icons/bi";
import { TiSocialLinkedin } from "react-icons/ti";
import { RiFacebookCircleLine } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../addons/img/Logo.svg';
import { useHistory } from "react-router";
import { ExternalLink } from 'react-external-link';
import NavAside from './Nav-aside';
import Hamburger from '../components/Hamburger';

// const mediaQuery = window.matchMedia( '( min-width: 768px )' )

// // Note the `matches` property
// if ( mediaQuery.matches ) {
//   console.log('Media Query Matched!')
// }


const Nav = () => {
    //STATE
    const [isOpen, setHamburger] = useState(false);
    const [isInitial, setInitial] = useState(true);


    //History to handle pages history
    const history = useHistory();//for delayLink()

    //Menu
    let menu = useRef(null);
    

        
// LOGO HOVER ANIMATION
const logoContainer = useRef(null);
const handleLogoHover= () => {
    // Get logo elements to animate 
        const [logoEl]= logoContainer.current.children; 
        const logoB2 = logoEl.getElementById('b-2');
        const logoR1 = logoEl.getElementById('r-1');
        
    //Setup
        gsap.set(logoB2, {x: '-=0', y: '-=0'});
        const tlLogo = gsap.timeline({defaults: {ease: 'power3.easeOut'}});

    //Animation
        tlLogo
        .fromTo(logoB2, {}, {duration: .5, x: '+=10', y: '-=10'})
        .fromTo(logoR1,{},{duration: .5, y: '+=25'},'-=.5');        
}
// LOGO LEAVE (mouse over)  ANIMATION
const handleLogoLeave= () => {
    // Get logo elements to animate 
        const [logoEl]= logoContainer.current.children; 
        const logoB2 = logoEl.getElementById('b-2');
        const logoR1 = logoEl.getElementById('r-1');

    //Setup
        gsap.set(logoB2, {x: '-=0', y: '-=0'});
        const tlLogo = gsap.timeline({defaults: {ease: 'power3.easeOut'}});

    //Animation
        tlLogo
        .fromTo(logoB2, {x: '-=0'}, {duration: .5, x: '0',y: '0'})
        .fromTo(logoR1,{},{duration: .5, y: '0'},'-=.5');        
}/// LOGO ANIMATIONS 


   
// This function is handling every page leave - for page-container
const pageLeave = ()=> {
// Get page 
const [page]= document.getElementsByClassName('page-container'); 

//Setup
gsap.set(page, {x: '-=0', y: '-=0'});
const tlPageLeave = gsap.timeline({defaults: {ease: 'power3.easeOut'}});

//Animation
tlPageLeave
.fromTo(page,{x: '-=0', y: '-=0'}, {duration: .6, x: '+=200', autoAlpha: 0}); 
}
        

// This function is delaying page leave - waiting for animation finish
const delayLink = (link,e) =>  { 
    let prevLink = history.location.pathname;
    if (prevLink === link) {return}
    e.preventDefault();
    pageLeave();

    setTimeout(() => { 
            history.push(`${link}`) 
        },400);
        //all changes need to be applied also in button.js
}//delayLink
    


// Menu animation
useEffect(() => {
    // First page load - do nothing
    if (isInitial === true && isOpen === false){
        //Return - dont show menu on page load
            // console.log('WCZYTANIE STRONY');
            return;
    }
    // FIRST MENU OPEN
    if (isInitial === true && isOpen === true) { 
        //Open menu animation - first time
            // console.log('1 - wczytanie menu');

            //Elements to animate
            const logo = document.getElementById('logo-container');
            const navLinks = document.getElementById('nav-links').children;
            const footerLinks = document.getElementById('__social-links').children;
            const navAside = document.getElementById('nav-aside');
         
            //Menu - Setup
            const tlMenu = gsap.timeline({defaults: {ease: 'power3.easeOut'}});
            gsap.set([menu, logo, navLinks, footerLinks, navAside], {autoAlpha: 0});
    
            //Menu - Animation 1
            tlMenu
            .fromTo(menu, {display: 'none'}, { display: 'grid'})
            .fromTo(menu, {x:'-=100%'}, { duration: .4, autoAlpha: 1,x: '0'})
            .fromTo(logo, {}, { duration: .1, autoAlpha: 1, opacity: 1}, "-=0.45")
            .fromTo(navLinks, {y: '-=15'}, { duration: .4,stagger: .05, autoAlpha: 1, y: '0'})
            .fromTo(footerLinks, {y: '-=15'}, { duration: .4,stagger: .1, autoAlpha: 1,  y: '0'}, "-=0.2")
            .fromTo(navAside, {y: '+=25'}, { duration: .1, autoAlpha: 1, y: '0'}, "-=0.2")
    } 
    // SECOND+ MENU OPEN
    if (isOpen === true && isInitial === false) {
        //Open menu animation - second+ time
            // console.log('2 - wczytanie menu');

            //Menu - Setup
            const tlMenu2 = gsap.timeline({defaults: {ease: 'power3.easeOut'}});
            gsap.set([menu,], {autoAlpha: 1, opacity: 1});

            //Menu - Animation
            tlMenu2
            .fromTo(menu, {display: 'none',x:'-=100%' }, {duration: .4, display: 'grid',x: '0' })

    }
    // CLOSE MENU
    if (isOpen === false) {
        //Close menu animation
            // console.log('0 - zamknij menu');
            const tlMenu3 = gsap.timeline({defaults: {ease: 'power3.easeOut'}});
            gsap.set([menu,], {autoAlpha: 1, opacity: 1});

            //Menu CLOSE- Animation
            tlMenu3
            // .fromTo(menu, {display: 'flex'}, { duration: .1, display: 'none'})
            .fromTo(menu, {x:'-=0', display:'grid'}, { duration: .4, autoAlpha: 0, opacity: 0,x: '-=100%', display: "none" })




            // gsap.to([menu],{
            //     duration: 0.4,
            //     x: '-=25%',
            //     ease: "power3.inOut"
            // })
            // gsap.to([menu],{
            //     duration: .6,
            //     css: { display: "none"}
            // })
            if(isInitial) {setInitial(false)};
    } 
}, [isInitial, isOpen])

    


    return ( 
        <>
        <Hamburger isOpen={isOpen} setHamburger={setHamburger} setInitial={setInitial} isInitial={isInitial} />
        <nav ref={el => (menu = el) } id="main-nav" className={isOpen ? "main_nav __show-nav" : "main_nav __hide-nav"} >
                <section id='logo-container'>
                    <NavLink 
                        onClick={(e)=> delayLink('/',e)} exact to='/' 
                        activeClassName="active"  
                        onMouseEnter={handleLogoHover}
                        onMouseLeave={handleLogoLeave}>
                        
                        <div ref={logoContainer} className="logo">
                            <Logo />
                        </div>
                    </NavLink>

                </section>
                
            

                <ul id="nav-links" className="nav-links">
                    <NavLink onClick={(e)=> delayLink('/',e)} exact to='/' activeClassName="active">
                        <li className="nav-home">
                            <AiOutlineHome />
                        </li>
                    </NavLink>
                        
                    <NavLink onClick={(e)=> delayLink('/About',e)} to='/About'>
                        <li className="nav-about">
                        <SiAboutDotMe />
                        </li>
                    </NavLink>

                    <NavLink onClick={(e)=> delayLink('/Skills',e)} to='/Skills'>
                        <li className="nav-skills">
                            <VscSettingsGear />
                        </li>
                    </NavLink>

                    <NavLink onClick={(e)=> delayLink('/MyWork',e)} to='/MyWork'>
                        <li className="nav-work">
                            <FaRegEye />
                        </li>
                    </NavLink>

                    <NavLink onClick={(e)=> delayLink('/Contact',e)} to='/Contact'>
                        <li className="nav-contact">
                            <BiEnvelopeOpen />
                        </li>
                    </NavLink>
                </ul>

                
            
            <footer id="footer" className="nav-socials">
                    <ul id="__social-links" className="__social-links"> 
                        <ExternalLink href="https://www.facebook.com/kamil.jasinski.9/">
                            <li><RiFacebookCircleLine /></li>
                        </ExternalLink>

                        <ExternalLink href="https://github.com/Kamil-Jasinski">
                            <li><AiFillGithub /></li>
                        </ExternalLink>

                        <ExternalLink href="https://www.linkedin.com/in/kamil-jasiński-329a501b5">
                            <li><TiSocialLinkedin /> </li>
                        </ExternalLink>
                    
                    </ul>
                    <NavAside />
                </footer>
           
            
        </nav>
        </>
     );
}
 
export default Nav;