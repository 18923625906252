import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = () => {
  // initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map-container',
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      style: 'mapbox://styles/justargi/ckhizx07k13o619n04yircelz',
      center: [22.0774, 50.1554],
      zoom: 16.7,
    });
    
    // eslint-disable-next-line no-unused-vars
    var marker = new mapboxgl.Marker()
    .setLngLat([22.07546, 50.155448])
    .addTo(map);

    // add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    // clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (

  <div id="map-container" className="map-container"/>

  )
};

export default Map;